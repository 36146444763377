var searchKeys = [{
  key: 'dates',
  label: '提交时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始日期", "结束日期"],
  dates: true,
  required: false,
  rules: [{
    required: false
  }]
}, {
  key: "flowNo",
  label: "流水号",
  placeholder: "流水号",
  required: false,
  rules: [],
  input: true
}, {
  key: "userType",
  label: "用户类型",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "user",
  label: "用户",
  placeholder: "名称/邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "userId",
  label: "UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "type",
  label: "事项",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };