var columns = [{
  title: 'UID',
  dataIndex: 'userid',
  key: 'userid',
  // width: '7%',
  scopedSlots: {
    customRender: 'userid'
  }
}, {
  title: '商家名称',
  dataIndex: 'userName',
  key: 'userName',
  // width: '7%',
  scopedSlots: {
    customRender: 'userName'
  }
}, {
  title: '商家类型',
  dataIndex: 'userTypeName',
  key: 'userTypeName',
  // width: '5%',
  scopedSlots: {
    customRender: 'userTypeName'
  }
}, {
  // title: '邮箱',
  dataIndex: 'userEmail',
  key: 'userEmail',
  // width: '10%',
  scopedSlots: {
    customRender: 'userEmail'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '流水号',
  dataIndex: 'flowno',
  key: 'flowno',
  // width: '10%',
  scopedSlots: {
    customRender: 'flowno'
  }
}, {
  // title: '事项',
  dataIndex: 'typeName',
  key: 'typeName',
  // width: '10%',
  scopedSlots: {
    customRender: 'typeName'
  },
  slots: {
    title: 'typetit'
  }
}, {
  title: '充值金额($)',
  dataIndex: 'rechargeamount',
  key: 'rechargeamount',
  // width: '10%',
  scopedSlots: {
    customRender: 'rechargeamount'
  }
}, {
  title: '鉴定币交易量（个）',
  dataIndex: 'amount',
  key: 'amount',
  // width: '10%',
  scopedSlots: {
    customRender: 'amount'
  }
}, {
  title: '鉴定币余额（个）',
  dataIndex: 'surplus',
  key: 'surplus',
  // width: '10%',
  scopedSlots: {
    customRender: 'surplus'
  }
}, {
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '5%',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns };