import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("span", {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c("span", {
    staticClass: "money"
  }, [_vm._v("鉴定币总余额（$）")]), _vm._v("： "), _c("span", {
    staticClass: "balance"
  }, [_vm._v(_vm._s(_vm.balanceMoney || _vm.res.balance))])])]), _c("a-card", {
    staticStyle: {
      "overflow-x": "scroll",
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(record, index) {
        return index;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "typeName",
      fn: function fn(text, record) {
        return _c("span", {}, [[5, 6, 7, 8, 9].includes(record.type) ? _c("span", [_vm._v(" " + _vm._s(record.typeName) + "-" + _vm._s(record.brandName) + "-" + _vm._s(record.flowno))]) : _c("span", [_vm._v(_vm._s(record.typeName))])]);
      }
    }, {
      key: "userEmail",
      fn: function fn(text, record) {
        return [_c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isLock,
            expression: "isLock"
          }]
        }, [_vm._v(_vm._s(record.userEmail && record.userEmail.replace(/(?<=.{1})[^@]+(?=@)/, "****")))]), _c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isLock,
            expression: "!isLock"
          }]
        }, [_vm._v(_vm._s(record.userEmail))])];
      }
    }, {
      key: "userName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(" " + _vm._s(record.userName) + " ")])];
      }
    }, {
      key: "rechargeamount",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(record.rechargeamount || "---") + " ")];
      }
    }, {
      key: "flowno",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(record.flowno || "---") + " ")];
      }
    }, {
      key: "userTypeName",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(record.userTypeName || "---") + " ")];
      }
    }, {
      key: "surplus",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(record.surplus || "---") + " ")];
      }
    }, {
      key: "amount",
      fn: function fn(text, record) {
        return [_c("span", {
          class: {
            newStyle: record.amount < 0
          }
        }, [_c("span", [_vm._v(" " + _vm._s(record.amount > 0 ? "+" : "") + " ")]), _vm._v(" " + _vm._s(record.amount || "---") + " ")])];
      }
    }])
  }, [_c("span", {
    attrs: {
      slot: "emailTit"
    },
    slot: "emailTit"
  }, [_vm._v("邮箱 ")]), _c("span", {
    attrs: {
      slot: "typetit"
    },
    slot: "typetit"
  }, [_vm._v("事项 "), _c("img", {
    staticStyle: {
      width: "16px",
      height: "16px"
    },
    attrs: {
      src: require("../../assets/img/translate.png"),
      alt: ""
    },
    on: {
      click: function click($event) {
        return _vm.translateWord(true);
      }
    }
  })])]), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("div", {
    staticClass: "translate"
  }, [_vm.isVisible == true ? _c("VueDragResize", {
    attrs: {
      isActive: true,
      w: 1150,
      h: 190,
      isDraggable: true,
      isResizable: false,
      sticks: ["br"]
    }
  }, [_c("div", {
    staticClass: "box",
    style: {
      width: +_vm.vw + "px",
      height: +_vm.vh + "px"
    }
  }, [_c("img", {
    staticClass: "word",
    attrs: {
      src: require("../../assets/img/word.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "close",
    attrs: {
      src: require("../../assets/img/Vector.png"),
      alt: ""
    },
    on: {
      click: function click($event) {
        return _vm.translateWord(false);
      }
    }
  })])]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };